<template>
  <div class="app">
    <div class="burger-menu" v-if="isMenuBurger">
      <input id="toggle" type="checkbox">
      <!-- ВАЖНО: любой элемент, который мы хотим модифицировать при изменении состояния чекбокса, является «братским» элементом для чекбокса -->
      <!-- Эта метка привязана к чекбоксу и будет содержать переключение «кнопок» -->
      <label class="toggle-container" for="toggle">
        <!-- Если меню открыто, то здесь будет иконка «X», в противном случае просто иконка гамбургера -->
        <span class="button button-toggle"></span>
      </label>
      <!-- Навигация -->
      <nav class="nav">
        <a @click="isAbout = true, isServices = false, isAnswer = false, isContacts = false" class="nav-item" href="#">О нас</a>
        <a @click="isServices = true, isAbout = false, isAnswer = false, isContacts = false" class="nav-item" href="#">Услуги</a>
        <a @click="isAnswer = true, isAbout = false, isServices = false, isContacts = false" class="nav-item" href="#">Отвечают юристы</a>
        <a @click="isAbout = false, isServices = false, isAnswer = false, isContacts = true" class="nav-item" href="#">Контакты</a>
      </nav>
    </div>
    <header>
      <div class="header-contact">
        <div class="header__logo" style="cursor: pointer" @click=";isAbout = false;isServices = false;isContacts = false;isAnswer = false">
          <h2>Призывник</h2>
          <img class="" style="width: 70px"  src="./assets/img/kisspng-nautical-star.png" alt="">
        </div>
        <p @click="isPopup = 11"><img src="./assets/img/geo.png" style="max-width: 50px; transform: translateY(5px)" alt=""> {{clientCity}}</p>
        <div class="header-phone">
          <img class="trembling header__icons" src="./assets/img/phone-icon_34382.png" alt="">
          <a class="h3" href="tel:+79992220111">+7 (999) 222-0-111 </a>
        </div>
      </div>
      <div v-if="!isMenuBurger" class="header__navigation">
        <a @click="isAbout = true, isServices = false, isAnswer = false, isContacts = false" href="#">О нас</a>
        <a @click="isServices = true, isAbout = false, isAnswer = false, isContacts = false" href="#">Услуги</a>
        <a @click="isAnswer = true, isAbout = false, isServices = false, isContacts = false" href="#">Отвечают юристы</a>
        <a @click="isAbout = false, isServices = false, isAnswer = false, isContacts = true" href="#">Контакты</a>
      </div>
    </header>
    <div v-if="!isAbout && !isServices && !isContacts && !isAnswer" class="center">
      <div class="center__logo-wrap">
        <div :class="{ 'anim-text-red': animText1 }" class="center__logo-text-red"><p>Освобождение</p><img src="./assets/img/layer-red.svg" alt="Освобождение от армии"></div>
        <div :class="{ 'anim-text-blue': animText1 }" class="center__logo-text-blue"><p>от армии законно <br><span>C 2011 ГОДА</span></p><img src="./assets/img/layer-blue.svg" alt="Освобождение от армии"></div>
      </div>
      <div ref="anim-text" class="center__logo-text">ПРИЗЫВНИК</div>
      <div class="center__foot-wrap">
<!--        <div :class="{ 'anim-text': animText2 }" class="center__foot-table">-->
<!--          <div class="center__sale-right">Для призывников<br><span class="subtitle">Работаем со всеми городами РФ</span></div>-->
<!--          <div class="center__sale-num h3">-15 %</div>-->
<!--          <div class="center__sale">Скидка<br><span class="subtitle">До 28.08</span></div>-->
<!--        </div>-->
        <div @click="isPopup = 1" class="center__btn btn">
          <span><img src="./assets/img/btn.png" alt=""></span>
          Узнать больше
        </div>
      </div>
      <div class="center__btn-subtitle subtitle">
        АНО "ПЕРВОЕ АРБИТРАЖНОЕ УЧРЕЖДЕНИЕ"
        115280, Москва, Автозаводская, 23 а к 2
      </div>
    </div>
    <transition
            name="v-transition-animate"
            class="messages_list"
    >
      <div class="quest" v-if="isPopup >= 0 && isPopup !== 11">
        <div class="quest-wrap">
          <quest
                  v-if="isPopup === 1"
                  :isPopup="isPopup"
                  @nextQuest="nextQuest"
                  @removePopup="removePopup"
                  @result="resultPush"
                  question="Какая услуга вас интересует?"
                  :buttons="[
                      'Военный билет по здоровью',
                      'Военный билет по достижении возроста 27 лет',
                      'Не берут в армию', 'Отсрочка от армии'
                    ]"
          />
          <quest
                  v-if="isPopup === 2"
                  :isPopup="isPopup"
                  @nextQuest="nextQuest"
                  @removePopup="removePopup"
                  @result="resultPush"
                  question="Кем вы являетесь?"
                  :buttons="[
                      'Призывник',
                      'Родитель',
                    ]"
          />
          <quest
                  v-if="isPopup === 3"
                  :isPopup="isPopup"
                  @nextQuest="nextQuest"
                  @removePopup="removePopup"
                  @result="resultPush"
                  question="Вы состоите на воинском учете?"
                  :buttons="[
                      'Да',
                      'Нет',
                      'Затрудняюсь ответить'
                    ]"
          />
          <quest
                  v-if="isPopup === 4"
                  :isPopup="isPopup"
                  @nextQuest="nextQuest"
                  @removePopup="removePopup"
                  @result="resultPush"
                  question="Есть ли у вас проблемы со здоровьем?"
                  :buttons="[
                      'Да',
                      'Нет',
                      'Затрудняюсь ответить'
                    ]"
          />
          <quest
                  v-if="isPopup === 5"
                  :isPopup="isPopup"
                  @nextQuest="nextQuest"
                  @removePopup="removePopup"
                  @result="resultPush"
                  question="Вы уже получили повестку из военкомата для прохождения воинской службы?"
                  :buttons="[
                      'Да',
                      'Нет',
                    ]"
          />
          <quest
                  v-if="isPopup === 6"
                  @sendForm="sendForm"
                  :isPopup="isPopup"
                  @nextQuest="nextQuest"
                  @removePopup="removePopup"
                  @result="resultPush"
          />
          <quest
                  v-if="isPopup === 7"
                  :isPopup="isPopup"
                  @nextQuest="nextQuest"
                  @removePopup="removePopup"
                  @result="resultPush"
          />
        </div>
      </div>
    </transition>
    <notice
            v-if="isPopup === 11"
            @removePopup="removePopup"
            @setClientCity="setClientCity"
            :isPopup="isPopup"
            :title="title"
            :subtitle="subtitle"
    />
    <about
            v-if="isAbout"
            @backToHome="backToHome"
    />
    <services
            v-if="isServices"
            @backToHome="backToHome"
    />
    <contacts
            v-if="isContacts"
            @backToHome="backToHome"
    />
    <answer
            v-if="isAnswer"
            @backToHome="backToHome"
    />
  </div>
</template>

<script>
import quest from './components/quest'
import axios from 'axios'
import notice from './components/notification'
import about from './components/about-us'
import services from './components/services'
import contacts from './components/contacts'
import answer from './components/answer'
export default {
  name: 'App',
  components: {
    quest, notice, about, services, contacts, answer
  },
  computed: {
    isMenuBurger: function () {
      return window.innerWidth <= 750 // показ бургера только при ширине окна не более 750рх
    }
  },
  data () {
    return {
      animText1: false, // для анимации при загрузке страницы
      animText2: false, // для анимации при загрузке страницы
      isPopup: -1, // скрыть/показать попап PS попап notice === 11
      isAbout: false, // скрыть/показать страницу О нос
      isServices: false, // скрыть/показать страницу Услуги
      isContacts: false, // скрыть/показать страницу Контакты
      isAnswer: false, // скрыть/показать страницу Ответы юристов
      form: {}, // не помню че это, походу оно нахуй и не надо
      result: [], // сюда пушатся все нажатые кнопки (ответы из quest)
      title: null, // для попапа
      subtitle: null, // для попапа
      clientIp: null, // тут итак понятно
      clientCity: '' // тут итак понятно
    }
  },
  methods: {
    getCity () {
      if (localStorage.clientCity) {
        this.clientCity = localStorage.clientCity
        this.title = 'Ваш город ' + localStorage.clientCity
        this.subtitle = 'Ваш регион ' + localStorage.clientRegion
      } else {
        fetch('https://api.ipify.org?format=json') // апи стороннего ресурса для получения ип адреса клиента
                .then(response => response.json())
                .then(response => {
                  this.clientIp = response.ip; // узнается IP и присваивается
                  axios.get('https://ipwhois.app/json/' + this.clientIp + '?lang=ru') // апи для определения местоположения по IP
                          .then(res => { // далее идет присвоение этого ип и всякие простые манипуляции с ним
                            localStorage.clientCity = res.data.city
                            localStorage.clientRegion = res.data.region
                            this.clientCity = res.data.city
                            this.title = 'Ваш город ' + res.data.city
                            this.subtitle = 'Ваш регион ' + res.data.region
                            this.isPopup = 11 // чтоб показать попап notice
                          })
                });
      }
    },
    backToHome () {
      this.isAbout = false
      this.isServices = false
      this.isContacts = false
      this.isAnswer = false
    },
    setClientCity (payload) {
      this.clientCity = payload // payload это clientCity из компонента (новый введеный в инпуте город)
      this.removePopup() // закрываем окно вызвом функцию removePopup()
      this.title = payload
      this.subtitle = ''
      localStorage.clientCity = payload
      localStorage.clientRegion = ''
    },
    removePopup () {
      this.isPopup = -1 // закрываем попап
      this.result = [] // отчищаем ответы
    },
    async resultPush (button) {
      await this.result.push(button) // button это текст кнопки, на которую нажали. она и пушится в результат чтоб потом все это отправить
    },
    async nextQuest () {
      return this.isPopup++ // при ответе на вопрос (нажатие 1 из вариантов ответа) прибавляется цифра, так меняются quest
    },
    sendForm (form) {
      const formData = new FormData()
      formData.append('crm', '16')
      formData.append('pipe', '34')
      formData.append('contact[name]', form.name)
      formData.append('lead[471]', this.clientCity)
      formData.append('contact[473]', this.clientCity)
      formData.append('contact[466]', form.phone)
      formData.append('contact[467]', form.email)
      // formData.append('lead[214]', 'ЕБСА')
      // formData.append('note', 'Заявка с ЕБСА по статье ' + this.numArticle + '<br>' + this.result.join())
      // для mychance.avtourist.info
      formData.append('lead[541]', 'призывник.мфюц.рф')
      formData.append('note', 'Заявка с призывник.мфюц.рф: ' + '<br>' + this.result.join().replace(/[,]/g, '')); // удаление запятых из this.result
      axios.post('https://bez.v-avtoservice.com/api/import-lead',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
      ).then(res => {
        res.data.status === 'success' ? this.isPopup = 7 : alert('Произошла ошибка! Обновите страницу и попробуйте снова')
      })
      .catch(error => {
        error ? alert('Произошла ошибка! Обновите страницу и попробуйте снова') : false
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.animText1 = true
    }, 1000)
    setTimeout(() => {
      this.animText2 = true
    }, 2500)
    this.getCity()
  }
}
</script>

<style lang="scss">
  /* шрифты формата ttf не работают, поэтому подключаем с гугла */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');
  body {
    color: black;
    background-color: #ffffff;
    font-weight: normal;
    font-family: "Montserrat",serif;
    margin: initial;
    padding: 40px 5% 0;
    @media (max-width: 800px) { padding: 20px 5% 0; }
    @media (max-width: 600px) { padding: 10px 2% 0; }
    button {
      border: initial;
      cursor: pointer;
    }
    p {
      margin: initial;
    }
    a {
      text-decoration: initial;
      cursor: pointer;
      color: black;
    }
    h1 { font-size: calc( (100vw - 320px)/(1920 - 320) * (71 - 5) + 20px); }
    h2, .h2 { font-weight: 600;font-size: calc( (100vw - 320px)/(1920 - 320) * (60 - 5) + 18px); }
    h3, .h3 {font-size: 25px;font-weight: 600;}
    h4 {font-size: 16px;font-weight: normal;}
    h5, .h5 { font-size: 16px; opacity: 0.8}
    .trembling {
      display: inline-block;
      -webkit-animation-name: trembling;
      -webkit-animation-duration: 0.8s;
      -webkit-animation-delay: 1.3s;
      -webkit-transform-origin:50% 50%;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
    }
    .btn {
      display: flex;
      align-items: center;
      background: red;
      padding: 10px;
      width: 240px;
      min-width: 240px;
      justify-content: center;
      margin-left: auto;
      margin-right: 100px;
      border-radius: 10px;
      color: white;
      img {
        max-width: 25px;
        margin-right: 5px;
      }
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
    }
    .subtitle {
      font-size: 12px;
      color: #768c97;
      font-weight: normal;
    }
  }
  header {
    .header-contact {
      .header__icons {
        max-width: 50px;
      }
      .header__logo {
        display: flex;
        align-items: center;
        max-height: 50px;
        h2 {
          margin: initial;
          margin-right: 10px;
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @media (max-width: 520px) { h2{font-size:50px} }
      .header-phone {
        display: flex;
        width: 30%;
        min-width: 300px;
        a {
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }
    .header__navigation {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
    }
  }
  .center {
    .center__logo-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      min-height: 300px;
      .center__logo-text-red {
        width: 690px;
        position: relative;
        transform: translateX(-300px);
        opacity: 0;
        transition-duration: 0.8s;
        p {
          position: absolute;
          top: 15px;
          left: 85px;
          transform: rotate(-5deg);
          font-size: 60px;
          color: #ffffff;
          font-weight: bold;
          z-index: 2;
        }
        img {
          max-width: 100%;
          position: absolute;
          top: 0;
          z-index: 1;
        }
        &.anim-text-red {
          transform: translateX(0);
          opacity: 1;
        }
      }
      .center__logo-text-blue {
        width: 585px;
        position: relative;
        margin-top: 68px;
        transform: translateX(300px);
        opacity: 0;
        transition-duration: 0.8s;
        p {
          width: 100%;
          position: absolute;
          top: 40px;
          left: 55px;
          transform: rotate(-6deg);
          font-size: 50px;
          color: #ffffff;
          font-weight: bold;
          z-index: 2;
        }
        img {
          max-width: 100%;
          position: absolute;
          top: 0;
          z-index: 1;
        }
        span {
          font-size: 30px;
          font-weight: 700;
          color: #768c97;
          display: block;
          transform: rotate(3deg)translateY(20px);
          text-align: right;
        }
        &.anim-text-blue {
          transform: translateX(-50px)rotate(1deg);
          opacity: 1;
        }
      }
    }
    .center__logo-text {
      font-weight: 800;
      font-size: calc( (100vw - 320px)/(1920 - 320) * (121 - 5) + 47px);
    }
    .center__foot-wrap {
      display: flex;
      margin-top: 20px;
      .center__foot-table {
        display: flex;
        justify-content: space-between;
        div { margin: 0 10px }
        background: black;
        position: absolute;
        left: 0;
        border-radius: 0 10px 500px 0;
        padding: 10px 25px 10px 30px;
        transform: translateX(-300px);
        opacity: 0;
        transition-duration: 3s;
        .center__sale-right {
        }
        &.anim-text {
          transform: translateX(0);
          opacity: 1;
        }
        .center__sale-right {}
      }
      .center__btn {
        margin: 30px auto 0 auto;
        max-height: 40px;
      }
    }
    .center__btn-subtitle {
      max-width: 300px;
      display: block;
      margin-top: 10px;
      margin-left: auto;
      text-align: center;
      margin-right: auto;
    }
  }
  .burger-menu {
    /* Работа переключателя */
    #toggle {
      position: absolute;
      left: -100%;
      top: -100%;
    }
    #toggle:focus ~ .toggle-container .button-toggle {
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    #toggle:checked ~ .toggle-container .button-toggle {
      box-shadow: 0 0 0 500px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    #toggle:checked ~ .toggle-container .button-toggle:hover {
      box-shadow: 0 0 0 500px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    #toggle:checked ~ .toggle-container .button-toggle:before {
      transform: translateY(-50%) rotate(45deg) scale(1);
    }
    #toggle:checked ~ .toggle-container .button-toggle:after {
      transform: translateY(-50%) rotate(-45deg) scale(1);
    }
    #toggle:checked:focus ~ .toggle-container .button-toggle {
      box-shadow: 0 0 0 500px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    #toggle:checked ~ .nav {
      margin-bottom: 100px;
      pointer-events: auto;
      transform: translate(50px, 50px);
    }
    #toggle:checked ~ .nav .nav-item {
      color: #000000;
      letter-spacing: 0;
      height: 40px;
      line-height: 40px;
      margin-top: 0;
      opacity: 1;
      transform: scaleY(1);
      transition: 0.5s, opacity 0.1s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(1) {
      transition-delay: 0.15s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(1):before {
      transition-delay: 0.15s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(2) {
      transition-delay: 0.1s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(2):before {
      transition-delay: 0.1s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(3) {
      transition-delay: 0.05s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(3):before {
      transition-delay: 0.05s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(4) {
      transition-delay: 0s;
    }
    #toggle:checked ~ .nav .nav-item:nth-child(4):before {
      transition-delay: 0s;
    }
    #toggle:checked ~ .nav .nav-item:before {
      opacity: 0;
    }
    #toggle:checked ~ .dummy-content {
      padding-top: 30px;
    }
    #toggle:checked ~ .dummy-content:before {
      background-color: rgba(0, 0, 0, 0.3);
    }
    /* Кнопка для переключения */
    .button-toggle {
      position: absolute;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 25px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      border-radius: 100%;
      transition: 0.6s;
    }
    .button-toggle:hover {
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    .button-toggle:before, .button-toggle:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #EC7263;
      border-radius: 5px;
      transition: 0.5s;
    }
    .button-toggle:before {
      transform: translateY(-50%) rotate(45deg) scale(0);
    }
    .button-toggle:after {
      transform: translateY(-50%) rotate(-45deg) scale(0);
    }
    /* Меню */
    .nav {
      display: inline-block;
      margin: 25px 25px 20px;
      pointer-events: none;
      transition: 0.5s;
    }
    .nav-item {
      position: relative;
      display: inline-block;
      float: left;
      clear: both;
      color: transparent;
      font-size: 14px;
      letter-spacing: -6.2px;
      height: 7px;
      line-height: 7px;
      text-transform: uppercase;
      white-space: nowrap;
      transform: scaleY(0.2);
      transition: 0.5s, opacity 1s;
    }
    .nav-item:nth-child(1) {
      transition-delay: 0s;
    }
    .nav-item:nth-child(1):before {
      transition-delay: 0s;
    }
    .nav-item:nth-child(2) {
      transition-delay: 0.05s;
    }
    .nav-item:nth-child(2):before {
      transition-delay: 0.05s;
    }
    .nav-item:nth-child(3) {
      transition-delay: 0.1s;
    }
    .nav-item:nth-child(3):before {
      transition-delay: 0.1s;
    }
    .nav-item:nth-child(4) {
      transition-delay: 0.15s;
    }
    .nav-item:nth-child(4):before {
      transition-delay: 0.15s;
    }
    .nav-item:nth-child(1) {
      letter-spacing: -8px;
    }
    .nav-item:nth-child(2) {
      letter-spacing: -7px;
    }
    .nav-item:nth-child(n + 4) {
      letter-spacing: -8px;
      margin-top: -7px;
      opacity: 0;
    }
    .nav-item:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #EC7263;
      transform: translateY(-50%) scaleY(5);
      transition: 0.5s;
    }
    /* Фиктивное содержимое */
    .dummy-content {
      position: relative;
      text-align: center;
      transition: 0.5s;
    }
    .dummy-content:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 2;
      transition: 0.5s;
    }
    .circle {
      display: inline-block;
      width: 75px;
      height: 75px;
      background-color: #EC7263;
      border-radius: 100%;
    }
    .text {
      margin: 15px 0 30px;
    }
    .text span {
      display: inline-block;
      height: 10px;
      margin: 0 5px;
      background-color: #C06162;
      border-radius: 5px;
    }
    .text span:first-child {
      width: 50px;
    }
    .text span:last-child {
      width: 80px;
    }
    .square-top {
      display: inline-block;
      position: relative;
      width: 200px;
      height: 300px;
      background-color: #FEBE7E;
      z-index: 1;
    }
    .square-behind {
      display: inline-block;
      position: relative;
      top: -256px;
      width: 250px;
      height: 210px;
      background-color: #C28683;
    }
    .square-behind:before, .square-behind:after {
      position: absolute;
      content: '';
      top: 0;
      width: 40%;
      height: 100%;
    }
    .square-behind:before {
      left: 0;
      background-color: #9D567C;
    }
    .square-behind:after {
      right: 0;
      background-color: #958C6B;
    }
  }
  @media (max-width: 820px) {
    header {
      .header-contact {
        margin-bottom: 50px;
      }
    }
    .center {
      .center__logo-wrap {
        min-height: 250px;
        .center__logo-text-red {
          width: 490px;
          transform: translateX(-100px);
          p {
            top: 15px;
            left: 65px;
            transform: rotate(-6deg);
            font-size: 40px;
          }
          img {
          }
        }
        .center__logo-text-blue {
          width: 385px;
          margin-top: 57px;
          transform: translateX(100px);
          p {
            top: 26px;
            transform: rotate(-6deg);
            font-size: 30px;
          }
          &.anim-text-blue {
            transform: translateX(-80px) rotate(1deg);
          }
        }
      }
      .center__foot-wrap {
        .center__btn {
        }
      }
    }
  }
  @media (max-width: 500px) {
    .center {
      .center__logo-wrap {
        min-height: 150px;
        .center__logo-text-red {
          width: 290px;
          transform: translateX(-100px);
          p {
            top: 10px;
            left: 45px;
            transform: rotate(-6deg);
            font-size: 23px;
          }
          img {
          }
        }
        .center__logo-text-blue {
          width: 285px;
          margin-top: 31px;
          transform: translateX(100px);
          p {
            top: 20px;
            transform: rotate(-6deg);
            font-size: 20px;
          }
          span {
            font-size: 14px;
            transform: rotate(3deg)translateY(10px)translateX(-50px);
          }
          &.anim-text-blue {
            transform: translateX(-50px) rotate(1deg);
          }
        }
      }
      .center__foot-wrap {
        .center__btn {
          margin-top: 105px;
        }
      }
    }
  }
  /*  !!! Эффекты !!! */
  @-webkit-keyframes trembling {
    9% {
      -webkit-transform:translateX(5px) rotate(3deg);
      transform:translateX(5px) rotate(3deg)
    }
    19% {
      -webkit-transform:translateX(-3px) rotate(-2deg);
      transform:translateX(-3px) rotate(-3deg)
    }
    29% {
      -webkit-transform:translateX(3px) rotate(2deg);
      transform:translateX(3px) rotate(2deg)
    }
    40% {
      -webkit-transform:translateX(0px) rotate(0deg);
      transform:translateX(0px) rotate(0deg)
    }
    50% {
      -webkit-transform:translateX(0px) rotate(0deg);
      transform:translateX(0px) rotate(0deg)
    }
    58% {
      -webkit-transform:translateX(-2px) rotate(-1deg);
      transform:translateX(-2px) rotate(-1deg)
    }
    67% {
      -webkit-transform:translateX(0px) rotate(0deg);
      transform:translateX(0px) rotate(0deg)
    }
    79% {
      -webkit-transform:translateX(0px) rotate(0deg);
      transform:translateX(0px) rotate(0deg)
    }
    89% {
      -webkit-transform:translateX(0px) rotate(0);
      transform:translateX(0px) rotate(0)
    }
    99% {
      -webkit-transform:translateX(0px) rotate(0);
      transform:translateX(1px) rotate(0)
    }
  }
  .v-transition-animate-enter {
    opacity: 0;
  }
  .v-transition-animate-enter-active {
    transition: all .6s ease;
    transform: scale(0);
  }
  .v-transition-animate-enter-to {
    opacity: 1;
    transform: scale(1);
  }
  .v-transition-animate-leave {
    height: 50px;
    opacity: 1;
  }
  .v-transition-animate-leave-active {
    transition: transform .6s ease, opacity .6s, height .6s .2s;
  }
  .v-transition-animate-leave-to {
    height: 0;
    transform: scale(0);
    opacity: 0;
  }
  .v-transition-animate-move {
    transition: transform .6s;
  }
</style>
