<template>
    <div class="about">
        <p class="h2">О НАС</p>
        <br>
        <br>
        <p>Мы работаем с 2011 года, и за это время наработали уникальные правовые механизмы, которые позволяют нам добиться желаемого результата.</p>
        <br><br>
        <p>Мы работаем официально, что дает нам полномочия представлять интересы наших доверителей по делам любой категории сложности, включая уголовные.</p><br>
        <p>Мы предоставляем рассрочку на наши услуги.</p><br>
        <p>У нас самые низкие цены на услуги по защите прав призывников.</p><br>
        <p>Если наш доверитель попадает в армию - мы гарантируем полный возврат денег.</p><br>
        <p>За время работы с 2011 года мы провели более 1000 консультаций.</p><br>
        <br><br>
        <p class="h3">Мы работаем с призывниками из всех регионов России.</p>
        <div @click="backToHome" class="about__btn btn"><img src="../assets/img/back.png" alt="">Назад</div>
    </div>
</template>

<script>
  export default {
    name: "about-us",
    emits: [
      'backToHome'
    ],
    methods: {
      backToHome () {
        this.$emit('backToHome')
      }
    }
  }
</script>

<style lang="scss">
.about {
    .h2 {
        display: inline-block;
        margin: 0 auto;
    }
    h3 {
        display: inline-block;
        margin-left: auto;
        margin-right: 100px;
    }
    .about__btn {
        margin: 25px auto;
    }
}
</style>