<template>
    <div class="answer">
        <p class="h2">Правовые и юридические вопросы</p>
        <br>
        <br>
        <div class="answer__container">
            <p class="answer__title">У  мамы вторая группа инвалидности, положена ли отсрочка в армию?</p>
            <p class="answer__subtitle">В соответствии с п. «б» ст. 24 Закона РФ «О воинской обязанности и воинской службе» лицам, призванным на военную службу, занятым постоянным уходом за отцом, матерью, женой, родным братом, родной сестрой, дедушкой, бабушкой или усыновителем, предоставляется отсрочка от призыва в случае, если:<br>

                 - отсутствуют другие лица, обязанные по закону содержать указанных граждан;<br>

                - граждане не находятся на полном государственном обеспечении и нуждаются по состоянию здоровья в постоянном постороннем уходе.<br>

                Факт необходимости в постоянном постороннем уходе  должен быть подтвержден заключением федерального учреждения медико-социальной экспертизы по месту жительства граждан, призываемых на военную службу.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title"></p>
            <p class="answer__subtitle"></p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Есть ли отсрочка по беременности жены?</p>
            <p class="answer__subtitle">Согласно пп. «и» п. 1 ст. 24 «О воинской обязанности и воинской службе» отсрочка от призыва на военную службу предоставляется гражданам, имеющим ребенка и жену, срок беременности которой составляет не менее 26 недель. Документально подтверждены должны быть факт состояния в браке, наличие общего с женой ребенка, беременность сроком не менее 26 недель.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Можно ли получить отсрочку при поступлении в вуз после колледжа?</p>
            <p class="answer__subtitle">Согласно пп. «а» п. 2 ст. 24 Закона РФ «О воинской обязанности и воинской службе» граждане, призванные на военную службу, имеют право на отсрочку в связи с  поступлением в очное отделение имеющего государственную аккредитацию по соответствующей специальности вуза в случае если ранее гражданин указанным правом не пользовался, или если ему предоставлялась отсрочка по другим причинам, предусмотренным Федеральным Законом. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Ребенку 3 года и жена беременна. Есть ли отсрочка?</p>
            <p class="answer__subtitle">В соответствии с пп. «и» п. 1 ст. 24 «О воинской обязанности и воинской службе» гражданам, имеющим ребенка и жену, срок беременности которой составляет не менее 26 недель, предоставляется отсрочка от призыва на военную службу. Возраст совместного ребенка в нормативном документе не указан.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Предоставляется ли отсрочка по второй аспирантуре?</p>
            <p class="answer__subtitle">В законе РФ «О воинской обязанности и воинской службе» не указано количество предоставления отсрочки по обучению в аспирантуре, соответственно, получение отсрочки в этом случае возможно. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Если двое детей, как получить отсрочку?</p>
            <p class="answer__subtitle">Гражданам, имеющим двух и более детей, предоставляется отсрочка от призыва на военную службу в соответствии с пп. «г» п. 1 ст. 24 «О воинской обязанности и воинской службе». </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Обучение по не аккредитованной специальности дает отсрочку?</p>
            <p class="answer__subtitle">В соответствии с пп. «а» п.2 ст.24 ФЗ «О воинской обязанности и военной службе» отсрочка по обучению предоставляется только обучающимся на очной форме обучения в учебном заведении, имеющем государственную аккредитацию по соответствующей специальности.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Обязан ли я являться по звонкам из военкомата?</p>
            <p class="answer__subtitle">Согласно порядку призыва граждан на военную службу, определенному ст. 26 ФЗ «О воинской обязанности и военной службе», на мероприятия, связанные с призывом на военную службу, граждане вызываются  военного комиссариата. Другие формы вызова нормативным документом не предусмотрены.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Приходил участковый с повесткой. Его полномочия?</p>
            <p class="answer__subtitle">Сотрудник полиции имеет право вручить повестку военного комиссариата на основании письменного обращения военного комиссара об установлении местонахождения гражданина, которому не представилось возможным вручить повестку для явки в военный комиссариат. Полномочия оперуполномоченного участкового в данном случае регулируются Инструкцией, утвержденной совместным Приказом МО РФ, МВД России и ФМС России «Об организации взаимодействия военных комиссариатов, органов внутренних дел и территориальных органов Федеральной миграционной службы в работе по обеспечению гражданами РФ воинской обязанности.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что делать, если мама расписалась в повестке?</p>
            <p class="answer__subtitle">Расписка призывника в получении повестки необходима для доказывания факта оповещения данного призывника о необходимости явиться на мероприятия, связанные с призывом на военную службу. Доказать факт оповещения нужно:
                <br>
                а) в целях объявления в розыск гражданина, не являющегося по повесткам военного комиссариата, в порядке, предусмотренном Инструкцией, утверждённой совместным Приказом МО РФ, МВД России и ФМС России «Об организации взаимодействия военных комиссариатов, органов внутренних дел и территориальных органов Федеральной миграционной службы в работе по обеспечению гражданами РФ воинской обязанности»,
                <br>
                б) в целях привлечения к уголовной ответственности за уклонение от призыва на военную службу (ст. 328 УК РФ) гражданина, не являющегося по повесткам военного комиссариата.
                <br>
                В первом случае розыск может быть объявлен, если из подписи матери не следует, что это подпись именно матери.
                <br>
                Во втором случае уголовное дело не будет возбуждено, а возбуждённое уголовное дело
                <br>
                подлежит прекращению за отсутствием состава преступления в том случае, если будет установлен факт ненадлежащего оповещения призывника о явке на мероприятия, связанные с призывом.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Где проводят независимую медицинскую экспертизу?</p>
            <p class="answer__subtitle">Согласно п. 3 Положения о независимой военно-врачебной экспертизе, утвержденного Постановлением Правительства РФ от 28 июля 2008 года № 574, независимая военно-врачебная экспертиза проводится в соответствующих медицинских учреждениях, имеющих лицензию для проведения данной экспертизы.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Не согласен с решением призывной комиссии.</p>
            <p class="answer__subtitle">В случае несогласия с решением призывной комиссии лица, призванные  на военную службу, имеют право обжаловать решения об их призыве на военную  службу, предумотренное ст.46 Конституции Российской Федерации и п. 7 ст. 28 ФЗ «О воинской обязанности и военной службе». Жалоба может быть направлена в вышестоящую инстанцию - призывную комиссию субъекта РФ или суд.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Не согласен с диагнозом больницы. </p>
            <p class="answer__subtitle">Призывник вправе в судебном порядке требовать, в частности, повторного оказания медицинской услуги и компенсации морального вреда в порядке, предусмотренном ст. ст. 15, 29 Закона РФ «О защите прав потребителей».
                <br>
                Если призывник в качестве средства доказывания изберёт судебно-медицинскую экспертизу, то она покажет истинное состояние здоровья, и именно заключение этой экспертизы будет положено в основу решения суда.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Затягивают выдачу военного билета.</p>
            <p class="answer__subtitle">Срок выдачи военного билета определен Инструкцией, утвержденной Приказом МО РФ № 400 от  02 октября 2007 года, и составляет 10 дней со дня вынесения решения призывной комиссией решения.
                <br>
                В случае если данный срок не соблюдается, гражданин вправе требовать в судебном порядке признания незаконным бездействия военного комиссара. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">В армии «дедовщина». Что делать?</p>
            <p class="answer__subtitle">В случае проявления неуставных отношений в отношении военнослужащего последний имеет право обратиться в следственный орган при соответствующей военной прокуратуре с заявлением. Указанные действия уголовно наказуемы и квалифицируются по ст. 335 УК РФ.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Каким больницам верят военкоматы?</p>
            <p class="answer__subtitle">Военкомат направляют призывника на обследование в конкретные медицинские учреждения и на основании актов из этих учреждений выносят заключение о категории
                годности. Если врач-специалист по каким-то причинам не может дать заключение о годности, он дает заключение о нуждаемости в обследовании. На основании этого заключения призывная комиссия направляет призывника на обследование. Основанием вынесения заключения о нуждаемости в обследовании может быть ТОЛЬКО невозможность вынесения заключения о годности по причине неустранимых противоречий между диагнозом и результатами обследования, неполноты проведенного обследования и пр. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что означают категории годности Б-2, Б-3, Б-4?</p>
            <p class="answer__subtitle">Категория "Б" - годен с незначительными ограничениями, т.е. призыву подлежит.
                Цифра после буквы «Б» называет показатель предназначения, определяется в соответствии с Таблицей дополнительных требований Расписания болезней. Чем выше цифра, тем больше ограничений по роду войск, в которых может служить призывник.
                На практике, значения цифр при распределении по родам войск не всегда учитываются.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как получить военный билет после 27 лет? От военкомата скрывался. </p>
            <p class="answer__subtitle">Чтобы получить военный билет необходимо обратиться с письменным заявлением о выдаче военного билета в военный комиссариат. После чего вас направят на медицинское освидетельствование для определения категории годности к военной службе. После получения заключения вас направят на заседание призывной комиссии, которая вынесет решение о зачислении вас в запас и выдаче документа воинского учета гражданина, пребывающего в запасе, - военного билета.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как получить форму 32?</p>
            <p class="answer__subtitle">При рассмотрении вопроса о выезде гражданина из Российской Федерации военным комиссариатом (по месту жительства), гражданину оформляющему документы на выезд, выдается справка (форма №32), если в отношении его призывной комиссией не было принято решение о призыве в вооруженные силы или о направлении на альтернативную гражданскую службу. Призывнику, у которого нет права на отсрочку получить справку из военкомата для ОУФМС России непросто, поэтому обстоятельства каждого ее получения индивидуальны. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Какова ответственность за неявку по полученной повестке?</p>
            <p class="answer__subtitle">Статьи 21.5 и 21.6 Кодекса об административных правонарушениях РФ  предусматривают штрафы за неявку в военкомат в размере от 100 до 500 рублей. Они накладываются на граждан, не явившихся по повестке:
                <br>
                - для прохождения медицинского освидетельствования;
                <br>
                - для постановки или снятия с воинского учета;
                <br>
                - для предоставления информации об изменившихся личных данных и т. п.
                <br>
                Уголовная ответственность граждан за уклонение от прохождения военной или альтернативной службы предусматривается ст. 328 УК РФ. Такой вид ответственности применяется в случаях, когда гражданин, несмотря на получение повесток, игнорирует необходимость явиться в военкомат, либо скрывается от военного комиссариата с целью уклонения от воинской службы. В соответствии с указанным источником права виновный

                наказывается:
                <br>
                - штрафом в размере до 200 тысяч рублей;
                <br>
                - штрафом, равным доходу осужденного за период до 1,5 лет;
                <br>
                - привлечением к принудительным работам на срок до 2 лет;
                <br>
                - арестом на срок до полугода;
                <br>
                - лишением свободы на период до 2 лет.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Если я отслужил в армии в другом государстве?</p>
            <p class="answer__subtitle">Граждане, прошедшие военную (НЕ АЛЬТЕРНАТИВНУЮ)  службу в другом государстве, освобождаются от призыва на военную службу в силу пп. «г» п. 1 ст. 23 ФЗ «О воинской обязанности и военной службе».</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что делать, если в военкомате потеряли личное дело?</p>
            <p class="answer__subtitle">В случае потери вашего личного дела вы можете требовать его восстановления. Если в личном деле содержались документы, восстановление которых невозможно или требует от вас затрат времени и средств,  вы можете в судебном порядке требовать компенсации морального вреда и возмещения убытков. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что такое контрольное медицинское освидетельствование?</p>
            <p class="answer__subtitle">Обоснованность освобождения призывников или предоставления им отсрочек от призыва на военную службу по состоянию здоровья проверяется путем изучения врачами - членами призывной комиссии субъекта Российской Федерации (из числа врачей военно - врачебной комиссии военного комиссариата субъекта Российской Федерации) всех личных дел призывников и медицинских документов, подтверждающих установленный диагноз и вынесенное заключение. Контрольное медицинское освидетельствование (КМО) – освидетельствование, которому подлежат граждане, в отношении которых принято решение о предоставлении отсрочки или освобождения от призыва на военную службу по состоянию здоровья, а также граждане, заявившие о несогласии с вынесенным заключением об их категории годности. В некоторых случаях врачи-члены призывной комиссии проводят контрольное медицинское освидетельствование на основании документов личного дела. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Для чего нужен военный билет после 27 лет?</p>
            <p class="answer__subtitle">Документ воинского учета необходим для трудоустройства на работу по трудовому договору (ст. 65 Трудового кодекса РФ). Кроме того, военный билет является обязательным документом при оформлении кредитов и ипотечных кредитов большинства банков.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Какие полномочия у врачей призывной комиссии? </p>
            <p class="answer__subtitle">Врач районной призывной комиссии, руководящий работой по медицинскому освидетельствованию граждан, подлежащих призыву на военную службу, дает заключение о годности  к военной службе на основании заключений врачей-специалистов.
                <br>
                Врачи-специалисты призывной комиссии субъекта РФ, участвующие в проведении медицинского освидетельствования и медицинского осмотра граждан, утверждающие или отменяющие заключения, данные на местном уровне.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как приостановить решение о призыве?</p>
            <p class="answer__subtitle">Решение призывной комиссии может быть обжаловано гражданином, призванным на военную службу, в суде в установленный законодательством Российской Федерации срок со дня получения копии указанного решения. Решение призывной комиссии приостанавливается по факту направления жалобы до вынесения решения призывной комиссии субъекта РФ или  вступления в законную силу решения суда.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Имеют ли право с улицы доставлять в военкомат?</p>
            <p class="answer__subtitle">В соответствии со ст. 27.2 КоАП РФ России в перечень мест доставления граждан входят отделы и помещения полиции, а также органов самоуправления сельских поселений, таким образом, доставление в военкомат является противоправным действием.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Дает ли служба в полиции отсрочку в армии?</p>
            <p class="answer__subtitle">В соответствии с пп. «з» п.1 ст.24 ФЗ «О воинской обязанности и военной службе» отсрочка предоставляется на время службы в органах полиции в случаях:
                <br>
                - окончания образовательного учреждения органов внутренних дел;
                <br>
                - получения высшего профессионального образования;
                <br>
                - поступления на службу в органы непосредственно после получения образования;
                <br>
                - получения специального звания.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Где можно пройти альтернативную гражданскую службу?</p>
            <p class="answer__subtitle">Граждане проходят альтернативную гражданскую службу индивидуально, а также в составе групп или формирований:
                <br>
                - в организациях, подведомственных федеральным органам исполнительной власти;
                <br>
                - в организациях, подведомственных органам исполнительной власти субъектов Российской Федерации;
                <br>
                - в организациях Вооруженных Сил Российской Федерации, других войск, воинских формирований и органов в качестве гражданского персонала.
                <br>
                Место прохождения альтернативной гражданской службы определяет Федеральная служба по труду и занятости (Роструд), руководствуясь ежегодно утверждаемым перечнем должностей, специальностей и организаций, в соответствии с образованием, опытом работы, специальностью, медицинскими противопоказаниями и семейным положением. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Когда начинается весенний призыв?</p>
            <p class="answer__subtitle">Призыв на военную службу граждан, не пребывающих в запасе, осуществляется два раза в год с 1 апреля по 15 июля на основании указов Президента Российской Федерации за следующими исключениями:
                <br>
                а) граждане, проживающие в отдельных районах Крайнего Севера или отдельных местностях, приравненных к районам Крайнего Севера, призываются на военную службу с 1 мая по 15 июля;
                <br>
                б) граждане, проживающие в сельской местности и непосредственно занятые на посевных и уборочных работах, призываются на военную службу с 15 октября по 31 декабря, то есть весной не призываются;
                <br>
                в) граждане, являющиеся педагогическими работниками образовательных организаций, призываются на военную службу с 1 мая по 15 июля.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Когда заканчивается весенний призыв?</p>
            <p class="answer__subtitle">В соответствии со ст. 25 ФЗ «О воинской обязанности и воинской службе» срок окончания весеннего призыва одинаков для всех категорий граждан – 15 июля. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Когда начинается осенний призыв?
            </p>
            <p class="answer__subtitle">Осенний призыв на военную службу граждан, не пребывающих в запасе, осуществляется 1 октября по 31 декабря на основании указов Президента Российской Федерации за следующими исключениями:
                <br>
                а) граждане, проживающие в отдельных районах Крайнего Севера или отдельных местностях, приравненных к районам Крайнего Севера, призываются на военную службу с 1 ноября по 31 декабря;
                <br>
                б) граждане, проживающие в сельской местности и непосредственно занятые на посевных и уборочных работах, призываются на военную службу с 15 октября по 31 декабря;
                <br>
                в) граждане, являющиеся педагогическими работниками образовательных организаций, призываются на военную службу с 1 мая по 15 июля, то есть освобождены от осеннего призыва.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Когда заканчивается осенний призыв?</p>
            <p class="answer__subtitle">В соответствии со ст. 25 ФЗ «О воинской обязанности и воинской службе» срок окончания весеннего призыва одинаков для всех категорий граждан – 31 декабря. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Чем грозит неявка по повестке?</p>
            <p class="answer__subtitle">В соответствии с Федеральным законом от 28 марта 1998 г. N 53-ФЗ "О воинской обязанности и военной службе" граждане, не пребывающие в запасе, обязаны являться по повесткам военного комиссариата.
            <br>
                Неисполнение данного законного требования может влечь привлечение к административной или, при наличии умысла уклониться таким образом от призыва, уголовной ответственности. Пункт 4 ст.31 Федерального закона №53-ФЗ требует считать гражданина, не являющегося по повесткам военного комиссариата, уклоняющимся от призыва и привлекать его к ответственности, предусмотренной действующим законодательством. В частности, порядок привлечения гражданина, не явившегося по повестке, к уголовной ответственности регламентирует Постановление Пленума Верховного Суда РФ №3 от 03.04.2008 г.
            </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что важно и нужно знать о медосмотре в военкомате?</p>
            <p class="answer__subtitle">Порядок организации и проведения медицинского освидетельствования граждан определяется Положением о военно-врачебной экспертизе, утвержденным Постановлением Правительства РФ от 25 февраля 2003 г. №123, Приказом Министра обороны РФ №240, Минздрава РФ №168 от 23.05.2001 «Об организации медицинского обеспечения подготовки граждан РФ к военной службе».
                Граждане при призыве на военную службу проходят медицинское освидетельствование врачами-специалистами: терапевтом, хирургом, невропатологом, психиатром, окулистом, оториноларингологом, стоматологом, а в случае необходимости - врачами других специальностей.
                <br>
                Перед визитом в военкомат необходимо собрать все медицинские документы, в том числе медицинскую карту амбулаторного больного. Если карты нет на руках, её нужно получить в поликлинике по месту жительства. Делать это нужно заранее, т.к. за месяц до начала призыва карту направят в отдел военкомата.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что нужно знать призывнику о ФЗ «О воинской обязанности и военной службе»?</p>
            <p class="answer__subtitle">Основные моменты, на которые призывнику нужно обратить внимание:
                <br>- в год достижения возраста 17 лет лица мужского пола подлежат первоначальной постановке на воинский учёт (ППВУ) и обязаны в период с 1 января по 31 марта явиться в военкомат. В ходе ППВУ врачи-специалисты определяют категорию годности призывника к военной службе и предварительно вид и род войск, куда он будет направлен служить;
                <br>- решение о призыве на военную службу или о предоставлении отсрочки (освобождения) от призыва может быть принято лишь по достижении призывником возраста 18 лет. При этом медицинское освидетельствование является обязательным, решение призывной комиссии может быть принято только по результатам медицинского освидетельствования;
                <br>- решение призывной комиссии, не устраивающее гражданина, может быть обжаловано им в суд или в вышестоящую призывную комиссию. В этом случае решение  будет приостановлено до вступления в законную силу решения суда или вынесения решения призывной комиссией субъекта.
                <br>- граждане, успешно окончившие 11 классов общеобразовательной школы, имеют право на отсрочку от призыва до 1 октября того же года, когда была окончена школа.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Какие бывают категории годности к военной службе?</p>
            <p class="answer__subtitle">По результатам медицинского освидетельствования дают заключение о годности гражданина к военной службе по следующим категориям:
                <br>
                <br>А - годен к военной службе;
                <br>Б - годен к военной службе с незначительными ограничениями;
                <br>В - ограниченно годен к военной службе;
                <br>Г - временно не годен к военной службе;
                <br>Д - не годен к военной службе.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Пришла повестка в военкомат. Что будет, если я не приду?</p>
            <p class="answer__subtitle">Повестка является документом, которым призывники вызываются в военный комиссариат для уточнения вопросов воинского учета (учетных данных гражданина) и проведения мероприятий, связанных с подготовкой и проведением призыва граждан на военную службу.
                <br>В случае неявки без уважительной причины по повестке военного комиссариата гражданин считается уклоняющимся от военной службы и привлекается к ответственности (административной или уголовной) в соответствии с законодательством Российской Федерации.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Обязательна ли справка  по форме 32 для оформления загранпаспорта?</p>
            <p class="answer__subtitle">Запрос в военный комиссариат о предоставлении информации о временном ограничении права на выезд из РФ или об отсутствии такого ограничения в течение трех календарных дней со дня приема заявления о выдаче «загранпаспорта» направляет непосредственно территориальный орган или подразделение ФМС, а отдел военного комиссариата рассматривает запрос в течение пяти календарных дней.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что означает категория «ограничено годен»?</p>
            <p class="answer__subtitle">С целью осуществления организованного призыва лиц призывного возраста на срочную военную службу, принятия на контрактную службу либо поступления в военные учебные заведения установлены и законодательно закреплены категории годности к военной службе, следуя которым, определяется способность молодого человека служить в Вооруженных силах в целом и в частности нести службу в отдельных видах и родах войск.
                <br>
                «В» — ограниченно годен к военной службе. Призывник получает освобождение от призыва в мирное время и зачисляется в запас. В военное время подлежит призыву, для укомплектования частей 2 очереди.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Нужно ли служить после военной кафедры?</p>
            <p class="answer__subtitle">В соответствии с п. 1 ст. 22 федерального закона «о воинской обязанности и военной службе» призыву на венную службу подлежат только граждане, не пребывающие в запасе. таким образом, окончание военной кафедры и зачисление в запас с присвоением воинского звания офицера освобождает гражданина от призыва на военную службу в мирное время. данный гражданин может быть призыва на военную службу только в военное время при объявлении мобилизации.  </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Что такое «Расписание болезней»?</p>
            <p class="answer__subtitle">Расписание болезней - Приложение к Положению о военно-врачебной экспертизе, утвержденному Постановлением правительства РФ от 25.02.03 №123, согласно которому определяется категория годности гражданина к военной службе.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Какие последствия могут быть за уклонение от службы?</p>
            <p class="answer__subtitle">В соответствии с п.2 ст. 28 ФЗ «О воинской обязанности и военной службе» №53-ФЗ от 28 марта 1998 г. в случае уклонения граждан от призыва на военную службу призывная комиссия или военный комиссариат направляют соответствующие материалы руководителю следственного органа следственного комитета при прокуратуре Российской Федерации по месту жительства указанных граждан для решения вопроса о привлечении их к ответственности.
                <br>
                Уклонение от призыва на военную службу при отсутствии законных оснований для освобождения от прохождения службы наказывается штрафом в размере до двухсот тысяч рублей или в размере заработной платы или иного дохода осужденного за период до восемнадцати месяцев, либо арестом на срок от трёх до шести месяцев, либо лишением свободы на срок до двух лет.</p>
        </div>
        <h2>Медицинские вопросы</h2>
        <div class="answer__container">
            <p class="answer__title">Каков перечень заболеваний, препятствующих поступлению на гос.службу?</p>
            <p class="answer__subtitle">Перечень заболеваний, препятствующих поступлению на государственную гражданскую службу Российской Федерации и муниципальную службу или её прохождению, определен
                <br>
                Приказом Министерства здравоохранения и социального развития РФ от 14 декабря 2009 г. № 984-н (приложение N 2).</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как диагностируется сотрясение мозга?</p>
            <p class="answer__subtitle">Любой диагноз подтверждается соответствующими заболеванию клинико-анамнестическими данными, доступными лабораторными и инструментальными методами исследования (УЗИ, Рентген и т.д.) пораженного органа или системы.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Можно ли отказаться от процедуры рентгена?</p>
            <p class="answer__subtitle">Право проходить или отказаться от прохождения процедуры рентгена закреплено в ст.33 «Основ законодательства РФ об охране здоровья граждан».</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Можно ли отказаться от обследования в психиатрическом стационаре?</p>
            <p class="answer__subtitle">В соответствии со ст. 29 Закона РФ «О психиатрической помощи и гарантиях прав граждан при ее оказании» основаниями для помещения гражданина в психиатрический стационар без его согласия или без согласия его законного представителя до постановления судьи являются условия:
                <br>
                 - если гражданин представляет непосредственную опасность для себя или окружающих;
                <br>
                 - является беспомощным;
                <br>
                 - существенный вред его здоровью будет нанесен в случае оставления без необходимой помощи.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Должны ли в больнице ставить гербовую печать?</p>
            <p class="answer__subtitle">Пп. «а» п.1 Приложения №32 к Приказу Минобороны РФ №400 определяет обязательное заверение «гербовой» печатью медицинского учреждения актов исследования состояния здоровья, представляемых в военкомат.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Можно ли отказаться от стационарного обследования?</p>
            <p class="answer__subtitle">В соответствии со ст. 33 «Основ законодательства РФ об охране здоровья граждан» гражданин вправе отказаться от стационарного обследования.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Возможно ли снять диагноз через 5 лет после его получения?</p>
            <p class="answer__subtitle">Возможно, в случае если повторное обследование опровергло ранее полученные сведения о состоянии здоровья.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как диагностируется плоскостопие и сколиоз?</p>
            <p class="answer__subtitle">Плоскостопие и сколиоз диагностирует врач-рентгенолог, ортопед и невролог на основании клинико-рентгеновских и функциональных  исследований.
                <br>
                Исследования, которые необходимо провести для установления указанных диагнозов, приведены в Приказе Комитета здравоохранения г. Москвы №180 от 3 апреля 2002 г.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как диагностируются заболевания почек?</p>
            <p class="answer__subtitle">Для диагностики заболеваний почек существует перечень клинико-анамнестических, лабораторных, инструментальных исследований: УЗИ, внутривенная урография, реносцинтиграфия, при необходимости делается пункционная биопсия.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как диагностируется старый перелом позвоночника?</p>
            <p class="answer__subtitle">Делается рентген позвоночника в 2х проекциях или компьютерной томографией (КТ) позвоночника, на которых  врач-рентгенолог должен обнаружить признаки перелома. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Был гепатит. Как это подтвердить и поможет ли?</p>
            <p class="answer__subtitle">Диагноз ставится на основании клинических проявлений и лабораторно-инструментальных методов исследования (анализы крови, УЗИ) при обращении в медицинское учреждение (поликлинику). Наличие хотя бы минимальной активности хронического гепатита, выявленной в ходе обследования, определяет носителя не годным к службе в армии.
            <br>
                Основанием для получения отсрочки от прохождения службы по состоянию здоровья является хронический гепатит «В» или «С». Гепатит «А» не переходит в хроническую форму.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Нетрадиционная сексуальная ориентация: как подтвердить?</p>
            <p class="answer__subtitle">Врачи психо-неврологического диспансера должны провести обследование посредством консультаций, проведения психологических тестов и на основании результатов поставить диагноз. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как диагностируется бронхиальная астма?</p>
            <p class="answer__subtitle">Диагноз ставится на основании характерных клинических проявлений, лабораторных (специальные кожные пробы, анализ крови) и инструментальных обследований (функция внешнего дыхания (ФВД), проба с бронхолитиком), сделанных в период обострения заболевания. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Можно ли отказаться от дополнительного обследования?</p>
            <p class="answer__subtitle">Отказаться от обследования можно сославшись на ст.33 «Основ законодательства РФ об охране здоровья граждан», особенно если вы уже неоднократно подтверждали свой диагноз по направлению от военкомата.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как получить военный билет по зрению?</p>
            <p class="answer__subtitle">Необходимо обратиться  к врачу-окулисту для постановки диагноза. Если диагноз непризывной, военкомат на основании заключения врача направит на повторное обследование в регламентированное медучреждение, в случае в случае подтверждения диагноза призывника признают негодным к прохождению военной службы и в установленном законодательством порядке выдадут военный билет.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как получить военный билет по заболеванию сердца?</p>
            <p class="answer__subtitle">Необходимо обратиться к врачу-кардиологу и пройти необходимые обследования. Если диагноз непризывной, военкомат на основании заключения врача направит на повторное обследование в регламентированное медучреждение, в случае подтверждения диагноза призывника признают негодным к прохождению военной службы и в установленном законодательством порядке выдадут военный билет.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Дает ли экзема и витилиго освобождение от армии?</p>
            <p class="answer__subtitle">Наличие даже редких обострений экземы (1 раз за последние 3 года) и ограниченной её формы к является основанием для получения отсрочки от службы в армии по состоянию здоровья. Для признания негодным к прохождению военной службы с витилиго, должно быть 3 и более депигментированных пятен на коже различных анатомических областей (спина, грудь, конечности).</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Как подтвердить энурез?</p>
            <p class="answer__subtitle">Для подтверждения диагноза необходимо наличие истории болезни, картина регулярных обращений с жалобами в поликлинику до настоящего момента, консультации невропатолога, уролога, нефролога, лабораторные и инструментальные обследования (анализы, УЗИ и т.п.). Диагноз подтверждается на основании клинико-анамнестических данных, положительной пробы со снотворным в условиях стационара.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Чем гастрит отличается от язвы? С чем не берут в армию?</p>
            <p class="answer__subtitle">Гастрит – воспаление слизистой оболочки желудка с возможным наличием мелких эрозий, не оставляющих рубцов после заживления. При язвенной болезни образуется дефект слизистой оболочки желудка, двенадцатиперстной кишки, после которого остаётся рубец. Язвенная болезнь желудка, двенадцатиперстной кишки является основанием для признания призывника негодным к прохождению военной службы по состоянию здоровья. </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Где можно найти список болезней, освобождающих от армии?</p>
            <p class="answer__subtitle">Список заболеваний и соответствие категорий годности этим заболеваниям можно найти в Приложении к Положению о военно-врачебной экспертизе, утвержденному Постановлением правительства РФ от 25.02.03 №123, ред. от 28.07.2008 г. («Расписание болезней).</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">У меня плохое зрение. Можно избежать армии?</p>
            <p class="answer__subtitle">Клиническая картина плохого зрения предполагает отсрочку от прохождения военной службы и присвоения призывнику различных категорий годности. Соответственно:
            <br>
                 - близорукость или дальнозоркость любого глаза в одном из меридианов более 12,0 дптр либо астигматизм любого вида на любом глазу с разницей рефракции в двух главных меридианах более 6,0 дптр - не годен к службе в армии - категория «Д»;
                <br>
                 - близорукость или дальнозоркость любого глаза в одном из меридианов более 8,0 дптр и до 12,0 дптр либо астигматизм любого вида на любом глазу с разницей рефракции в двух главных меридианах более 4,0 дптр и до 6,0 дптр или близорукость любого глаза на одном из меридианов более 6,0 дптр и до 8,0 дптр - ограниченно годен (не годен в мирное время) - категория «В»;
                <br>
                 - острота зрения одного глаза 0,09 и ниже или его слепота при остроте зрения другого глаза 0,3 и ниже, а также отсутствие глазного яблока при остроте зрения другого глаза 0,3 и ниже или острота зрения обоих глаз 0,2 и ниже - не годен к службе в армии - категория «Д»;
                <br>
                 - острота зрения одного глаза 0,09 и ниже или его слепота при остроте зрения другого глаза 0,4 и выше, а также отсутствие глазного яблока при остроте зрения другого глаза 0,4 и выше или острота зрения одного глаза 0,3 при остроте зрения другого глаза от 0,3 до 0,1 или острота зрения одного глаза 0,4 при остроте зрения другого глаза от 0,3 до 0,1 - ограниченно годен - категория «В»;
            </p>
        </div>
        <div class="answer__container">
            <p class="answer__title">У меня сколиоз. Возьмут в армию?</p>
            <p class="answer__subtitle">Фиксированные приобретенные искривления позвоночника, сопровождающиеся ротацией
                позвонков (сколиоз II степени) являются показаниями для присвоения призывнику категории «В» (ограниченно годен, то есть не годен в мирное время).</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Могут ли призвать в армию с пиелонефритом?</p>
            <p class="answer__subtitle">Диагноз «хронический пиелонефрит» является основанием для присвоения призывнику категории «В» (не годен в мирное время).
                <br>
                Диагноз устанавливается при наличии лейкоцитурии и бактериурии, сохраняющихся более 12 месяцев и выявляемых количественными методами, при условии исключения воспалительных заболеваний мочевыводящих путей и половых органов после обследования с участием дерматовенеролога, уролога (для женщин, кроме того, гинеколога) и обязательного рентгенурологического исследования. При необходимости проводится ультразвуковое и радиоизотопное исследование почек.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Может ли плоскостопие стать причиной освобождения от службы в армии?</p>
            <p class="answer__subtitle">Дают призывнику право на отсрочку в армии по состоянию здоровья и присвоение категории «В» (не годен в мирное время) виды заболевания: продольное плоскостопие III степени без вальгусной установки пяточной кости и явлений деформирующего артроза в суставах среднего отдела стопы или продольное или поперечное плоскостопие II степени с деформирующим артрозом II стадии суставов среднего отдела стопы.</p>
        </div>
        <div class="answer__container">
            <p class="answer__title">Имеют ли право врачи военкомата игнорировать справки из больницы?</p>
            <p class="answer__subtitle">Согласно п.2 ст.30 Основ законодательства Российской Федерации об охране здоровья за гражданином закреплено право пройти обследование в том медицинском учреждении, в котором, по его мнению, имеются квалифицированные специалисты и современное оборудование.
                <br>
                В соответствии с п.26 Приложения №1 к Приказу министра обороны РФ от 02.11.2007 г. №400 копии документов, предоставленные призывником, должны быть приобщены к материалам личного дела. В соответствии с п. 32 Приложения №1 к Приказу Министра обороны РФ от 02.11.2007 г. №400, до 15 марта (15 сентября) истребуются и изучаются также медицинские карты, выписки из медицинских карт. Таким образом, игнорировать справки из больниц в военкомате по закону не имеют права.</p>
        </div>
        <div @click="backToHome" class="about__btn btn"><img src="../assets/img/back.png" alt="">Назад</div>
    </div>
</template>

<script>
  export default {
    name: "answer",
    emits: [
      'backToHome'
    ],
    methods: {
      backToHome () {
        this.$emit('backToHome')
      }
    }
  }
</script>

<style lang="scss">
.answer {
    .h2 {
        display: inline-block;
        margin: 0 auto;
    }
    h3 {
        display: inline-block;
        margin-left: auto;
        margin-right: 100px;
    }
    .about__btn {
        margin: 25px auto;
    }
    .answer__container {
        .answer__title {
            font-size: 20px;
            font-weight: 600;
            margin: 10px 0;
        }
    }
}
</style>