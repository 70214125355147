<template>
    <div class="contacts">
        <img  src="../assets/img/address.jpg" alt=""><br>
        <p class="h2">Наши контакты:</p>
        <br>
        <br>
        <p>АНО "ПЕРВОЕ АРБИТРАЖНОЕ УЧРЕЖДЕНИЕ"</p>
        <p>Адрес: г. Красноярск ул. Дмитрия Мартынова, д. 12, офис 1-05.</p>
        <br>
        <br>
        <p>Телфон +7 (999) 222-0-111 </p>
        <div @click="backToHome" class="about__btn btn"><img src="../assets/img/back.png" alt="">Назад</div>
    </div>
</template>

<script>
  export default {
    name: "contacts",
    emits: [
      'backToHome'
    ],
    methods: {
      backToHome () {
        this.$emit('backToHome')
      }
    }
  }
</script>

<style lang="scss">
.contacts {
    .h2 {
        display: inline-block;
        margin: 0 auto;
    }
    h3 {
        display: inline-block;
        margin-left: auto;
        margin-right: 100px;
    }
    .about__btn {
        margin: 25px auto;
    }
}
</style>
