<template>
  <div @click="removePopup()" class="quest__close">
    <img src="../assets/img/close_remove.png" alt="">
  </div>
  <div class="quest-name">{{ question }}</div>
  <div class="btn-wrapper">
    <div v-for="button in buttons" :key="button" @click="result(question + ' : ' + button + '<br>')" class="quest__btn btn">{{ button }}</div>
  </div>
  <div class="form" v-if="isPopup === 6">
    <div class="quest-name">Спасибо за ответ! Заполните форму и получите консультацию
      <div class="input-wrap">
        <input v-on:keyup.enter="sendForm" ref="name" v-model="form.name" placeholder="Имя" type="text">
        <input v-on:keyup.enter="sendForm" ref="email" v-model="form.email" placeholder="Email" type="text">
<!--        <input ref="city" v-model="form.city" placeholder="Город" type="text">-->
        <input v-on:keyup.enter="sendForm" ref="phone" v-model="form.phone" data-inputmask="'mask': '+9(999)999-99-99'" placeholder="Телефон" type="text">
      </div>
    </div>
    <div class="btn-wrapper">
      <div @click="sendForm" onclick="ym(75508297,'reachGoal','order')" class="quest__btn btn">Отправить</div>
    </div>
  </div>
  <div class="thanks" v-if="isPopup === 7">
    <div class="quest-name">Спасибо! Заявка принята, ожидайте звонка
      <p class="h3">Наши контакты:</p>
      <a href="tel:+79992220111">Телефон +7 (999) 222-0-111</a>
      <a href="">Адрес: г. Красноярск, ул. Дмтрория Мартынова, д.12 офис 1-05.</a>
    </div>
  </div>
</template>

<script>
  import Inputmask from 'inputmask'
export default {
  name: 'quest',
  props: {
    question: String,
    buttons: Array,
    isPopup: Number,
  },
  emits: [
          'removePopup',
          'result',
          'nextQuest',
          'sendForm',
  ],
  data () {
    return {
      form: {
        name: null,
        email: null,
        city: null,
        phone: null
      }
    }
  },
  methods: {
    removePopup () {
      this.$emit('removePopup')
    },
    async result (button) {
      await this.$emit('result', button)
      this.$emit('nextQuest')
    },
    sendForm (e) {
      if (!this.form.name) {
        this.$refs.name.placeholder = 'Вам необходимо указать как вас зовут'
        this.$refs.name.classList.add('error-send')
        setTimeout(() => {
          this.$refs.name.classList.remove('error-send')
        }, 800)
      } else if (!this.form.phone) {
        this.$refs.phone.placeholder = 'Вам необходимо указать телефон'
        this.$refs.phone.classList.add('error-send')
        setTimeout(() => {
          this.$refs.email.classList.remove('error-send')
          this.$refs.phone.classList.remove('error-send')
        }, 800)
      } else {
        let reg = '^\\+\\d{1,3}\\s?\\(\\d{3}\\)\\s?\\d{3}(-\\d{2}){2}$' // для проверки правильности введенного номера
         if (this.form.phone.match(reg) !== null) {
           e.target.innerText = 'Подождите...'
           this.$emit('sendForm', this.form)
         } else {
           let checkConfirm = confirm('Номер не корректный. Пожалуйста укажите правильно номер вашего телефона')
           if (checkConfirm || !checkConfirm) {
             this.$refs.phone.classList.add('error-send')
             setTimeout(() => {
               this.$refs.phone.classList.remove('error-send')
             }, 800)
           }
         }
      }
    }
  },
  mounted() {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .quest {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0,0,0, 0.7);
    .quest-wrap {
      background: white;
      position: relative;
      width: 70%;
      min-height: calc(60% + 100px);
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 10px;
      .quest__close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
      .quest-name{
        position: absolute;
        top: calc(10% + 50px);
        text-align: center;
        width: 90%;
        left: 5%;
        font-size: calc( (100vw - 320px)/(1920 - 320) * (60 - 5) + 18px);
        color: black;
        margin: 0px 10px 30px 10px;
      }
      .btn-wrapper {
        position: absolute;
        bottom: 10%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .quest__btn {
          flex-basis: 35%;
          min-height: 50px;
          min-width: initial;
          text-align: center;
          margin-left: initial; margin-right: initial;
          margin: 10px;
      }
    }
  }
    .form {
      .quest-name {
        position: absolute;
        top: 50px;
        text-align: center;
        width: 90%;
        left: 3%;
        font-size: calc( (100vw - 320px)/(1920 - 320) * (60 - 5) + 18px);
        color: black;
        margin: 0px 10px 30px 10px;
        .input-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          input {
            width: 350px;
            padding: 5px;
            border-radius: 2px;
            margin: 10px;
            border: 1px dashed rgba(255,0,0, 0.5);
            transition-duration: 0.7s;
            &:focus {
              outline:none;
              border: initial;
              border-bottom: 2px dashed rgba(255,0,0, 0.5);
            }
            &.error-send {
              background-color: rgba(255,0,0, 0.5);
              -webkit-animation-name: error-send;
              -webkit-animation-duration: 0.8s;
              -webkit-transform-origin:50% 50%;
              -webkit-animation-iteration-count: infinite;
              -webkit-animation-timing-function: linear;
            }
          }
        }
      }
    }
    .thanks {
      p {
        margin-top: 10%;
      }
      a {
        display: block;
        font-size: 20px;
        color: black;
      }
    }
}
  @media (max-width: 600px) {
    .quest .quest-wrap { min-height: 60% }
  }
  @media (max-width: 500px) {
    .quest .quest-wrap { width: 100%; min-height: 60% }
  }
  @-webkit-keyframes error-send {
    9% {
      -webkit-transform:translateX(5px) rotate(3deg);
      transform:translateX(5px) rotate(3deg)
    }
    19% {
      -webkit-transform:translateX(-3px) rotate(-2deg);
      transform:translateX(-3px) rotate(-3deg)
    }
    29% {
      -webkit-transform:translateX(3px) rotate(2deg);
      transform:translateX(3px) rotate(2deg)
    }
    40% {
      -webkit-transform:translateX(-3px) rotate(-2deg);
      transform:translateX(-3px) rotate(-2deg)
    }
    50% {
      -webkit-transform:translateX(2px) rotate(1deg);
      transform:translateX(2px) rotate(1deg)
    }
    58% {
      -webkit-transform:translateX(-2px) rotate(-1deg);
      transform:translateX(-2px) rotate(-1deg)
    }
    67% {
      -webkit-transform:translateX(2px) rotate(1deg);
      transform:translateX(2px) rotate(1deg)
    }
    79% {
      -webkit-transform:translateX(-2px) rotate(-1deg);
      transform:translateX(-2px) rotate(-1deg)
    }
    89% {
      -webkit-transform:translateX(1px) rotate(0);
      transform:translateX(1px) rotate(0)
    }
    99% {
      -webkit-transform:translateX(-1px) rotate(0);
      transform:translateX(-1px) rotate(0)
    }
  }
</style>
