<template>
    <div class="about">
        <p class="h2">Наши услуги:</p>
        <br>
        <br>
        <p>"Отсрочка ото армии" - 25 000 рублей</p>
        <br><br>
        <p>"Военный билет по здоровью" -  50000 рублей,</p>
        <br><br>
        <p>"Военный билет по достижению возраста 27 лет" - 50000 рублелей</p>
        <br><br>
        <p class="h3">Мы работаем с призывниками из всех регионов России.</p>
        <div @click="backToHome" class="about__btn btn"><img src="../assets/img/back.png" alt="">Назад</div>
    </div>
</template>

<script>
  export default {
    name: "services",
    emits: [
      'backToHome'
    ],
    methods: {
      backToHome () {
        this.$emit('backToHome')
      }
    }
  }
</script>

<style lang="scss">
.about {
    .h2 {
        display: inline-block;
        margin: 0 auto;
    }
    h3 {
        display: inline-block;
        margin-left: auto;
        margin-right: 100px;
    }
    .about__btn {
        margin: 25px auto;
    }
}
</style>